import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { ObjectLiteral, StoryblokDatasource, StoryblokDatasourceResponse } from '~/types';

@Module({
  name: 'datasources/projectcategories',
  stateFactory: true,
  namespaced: true
})

export default class Projectcategories extends VuexModule {
  // --state
  data: ObjectLiteral = {
    de: [],
    en: []
  };

  loaded: string = '';

  // --getters
  get all (): StoryblokDatasource[] {
    return this.data[this.loaded];
  }

  get allValues (): string[] {
    const data = this.data[this.loaded];
    return data?.map((entry: StoryblokDatasource) => entry.value);
  }

  get entryById () {
    return (id: number) => {
      const data = this.data[this.loaded];
      return data?.find((entry: StoryblokDatasource) => entry.id === id);
    };
  }

  // --mutations
  @Mutation
  setData ({ entries, language }: { entries: StoryblokDatasource[], language: string }): void {
    this.data[language] = entries.slice(0);
  }

  @Mutation
  setLoaded (language: string): void {
    this.loaded = language;
  }

  // --actions
  @Action
  async fetchData ({ version, api, language }: { version: string, api: any, language: string }) {
    if (this.loaded === language) {
      return;
    }

    const res: StoryblokDatasource[] | null = await api
      .get('cdn/datasource_entries/', {
        version,
        dimension: language,
        datasource: 'project-categories'
      })
      .then(({ data }: StoryblokDatasourceResponse) => {
        if (data?.datasource_entries) {
          return data.datasource_entries;
        }
        return [];
      })
      .catch((err: any) => {
        console.log(err);
        return [];
      });
    if (res) {
      this.setData({ entries: res, language });
      this.setLoaded(language);
    }
  }
}
