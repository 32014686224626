






















import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichCallToAction, CTAStyles, LinkTypes } from '~/types';
import LinkManual from '~/components/utilities/links/LinkManual.vue';

@Component({
  components: { LinkManual }
})
export default class ButtonWrapper extends Vue {
  @Prop({ required: false, default: false }) url?: string | false;
  @Prop({ required: false, default: 'nolink' }) linktype?: LinkTypes | 'nolink';
  @Prop({ required: false, default: false }) disabled?: boolean;
  @Prop({ required: false, default: false }) body?: BlokRichCallToAction | false;
  @Prop() styles!: CTAStyles;

  get finalLinkType () {
    if (this.linktype === 'story') {
      return 'internal';
    } else if (this.linktype === 'email') {
      return 'email';
    } else {
      return 'external';
    }
  }
}
