import { render, staticRenderFns } from "./default.vue?vue&type=template&id=955efa24&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./default.vue?vue&type=style&index=1&id=955efa24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "955efa24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheMenuBar: require('/opt/build/repo/components/layout/header/TheMenuBar.vue').default,TheFooterBar: require('/opt/build/repo/components/layout/footer/TheFooterBar.vue').default,CalendlyButton: require('/opt/build/repo/components/elements/CalendlyButton.vue').default})
