









import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { BlokRichImage } from '~/types';
import MediaHandler from '~/components/utilities/media/MediaHandler.vue';

@Component({
  components: { MediaHandler }
})
export default class RichImage extends Vue {
  @Prop() body!: BlokRichImage;
}
