
















import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { StoryblokAsset } from '~/types';

@Component
export default class Lightbox extends Vue {
  @Prop() media!: StoryblokAsset;
  closeLightboxWithEscape (e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      this.$emit('closeLightbox');
    }
  }
  mounted () {
    document.onkeydown = this.closeLightboxWithEscape;
  }
}
