import Vue from 'vue';

Vue.directive('superscript', {
  bind (el) {
    transformText(el);
  },
  componentUpdated (el) {
    transformText(el);
  }
});

function transformText (element) {
  if (element.innerHTML) {
    element.innerHTML = element.innerHTML.replace(/(®)(?!<\/sup>)/gi, '<sup>$1</sup>');
  }
}
