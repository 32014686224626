import { Component } from 'nuxt-property-decorator';
import Vue from 'vue';
import { Breakpoints } from '~/types';

@Component
class BreakpointMixin extends Vue {
  private breakpoints: { [key: string]: { width: number } } = {
    sm: {
      width: 640
    },
    md: {
      width: 768
    },
    lg: {
      width: 1024
    },
    xl: {
      width: 1280
    },
    xxl: {
      width: 1536
    }
  };
  pagewidth: number = 0;

  pageIsSmallerThan (width: number): boolean {
    return this.pagewidth <= width;
  }

  pageIsBiggerThan (width: number): boolean {
    return this.pagewidth > width;
  }

  breakpointDown (breakpoint: Breakpoints): boolean {
    return this.pagewidth <= this.breakpoints[breakpoint]?.width || false;
  }

  breakpointUp (breakpoint: Breakpoints): boolean {
    return this.pagewidth > this.breakpoints[breakpoint]?.width || false;
  }

  private setPageWidth (width: number): void {
    this.pagewidth = width;
  }

  removeBr (text: string): string {
    return text.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ');
  }

  mounted () {
    this.setPageWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      this.setPageWidth(window.innerWidth);
    });
  }
}

export default BreakpointMixin;
