import { render, staticRenderFns } from "./RichVideoNative.vue?vue&type=template&id=02183de2&scoped=true&"
import script from "./RichVideoNative.vue?vue&type=script&lang=ts&"
export * from "./RichVideoNative.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02183de2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoNative: require('/opt/build/repo/components/utilities/videos/VideoNative.vue').default})
