














import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokItemSocialMedia } from '../../types';
import LinkManual from '@/components/utilities/links/LinkManual';
import Icon from '@/components/elements/Icon';

@Component({
  components: { LinkManual, Icon }
})
export default class SocialMediaLink extends Vue {
  @Prop() link!: BlokItemSocialMedia;
  @Prop({ required: false, default: 'light' }) mode?: 'light' | 'dark';
  get icon (): string|false {
    return require(`~/assets/icons/socials/${this.link.platform}.svg`);
  }
}
