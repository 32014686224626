













import { Vue, Component, InjectReactive } from 'nuxt-property-decorator';

@Component
export default class NavToggle extends Vue {
  @InjectReactive() readonly navOpen!: boolean;
}
