import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
// @ts-ignore
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer';
import RichImage from '~/components/richtext/RichImage.vue';
import RichTable from '~/components/richtext/RichTable.vue';
import RichCallToAction from '~/components/richtext/RichCallToAction.vue';
import RichMargin from '~/components/richtext/RichMargin.vue';
import RichVideoYoutube from '~/components/richtext/RichVideoYoutube.vue';
import RichVideoVimeo from '~/components/richtext/RichVideoVimeo.vue';
import RichVideoNative from '~/components/richtext/RichVideoNative.vue';
import RichBox from '~/components/richtext/RichBox.vue';

Vue.use(VueCompositionApi);
Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      RichImage,
      RichTable,
      RichCallToAction,
      RichMargin,
      RichVideoYoutube,
      RichVideoVimeo,
      RichVideoNative,
      RichBox
    }
  }
});
