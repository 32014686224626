








import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichVideoNative, BlokRichVideoYoutube } from '~/types';
import VideoNative from '~/components/utilities/videos/VideoNative.vue';

@Component({
  components: { VideoNative }
})
export default class RichVideoNative extends Vue {
  @Prop() body!: BlokRichVideoNative;
}
