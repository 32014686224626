









import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokItemLink, ObjectLiteral } from '~/types';

@Component
export default class LinkHandler extends Vue {
  @Prop() link!: BlokItemLink;

  get linktype (): string {
    return this.link.link.linktype;
  }

  get linktypes (): ObjectLiteral {
    return {
      story: {
        component: 'LinkInternal',
        content: {
          url: this.link.link.cached_url || '',
          title: this.link.link_title || '',
          hasPrep: this.link.link.prep || false,
          anchor: this.link.link.anchor || false
        }
      },
      url: {
        component: 'LinkExternal',
        content: {
          url: this.link.link.cached_url || '',
          title: this.link.link_title || ''
        }
      },
      asset: {
        component: 'LinkAsset',
        content: {
          url: this.link.link.cached_url || '',
          title: this.link.link_title || ''
        }
      },
      email: {
        component: 'LinkEmail',
        content: {
          email: this.link.link.email || '',
          title: this.link.link_title || ''
        }
      }
    };
  }
}
