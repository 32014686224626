























import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { ApiError } from '~/types';

@Component
export default class Error extends Vue {
  @Prop() error!: ApiError;

  head () {
    return {
      title: `${this.error.statusCode} - Error Seite`,
      titleTemplate: `${process.env.NUXT_APP_META_SITENAME} | %s` || 'Echt | %s'
    };
  }
  mounted () {
    if (process.browser) {
      this.$ScrollTrigger.config({
        autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load'
      });
    }
  }
}
