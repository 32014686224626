

































import { Component, InjectReactive, mixins, Watch } from 'nuxt-property-decorator';
import { directive } from 'vue-awesome-swiper';
import { getGlobal } from '~/store';
import { BlokItemLink, BlokItemSocialMedia, StoryblokRichText } from '~/types';
import BreakpointMixin from '~/mixins/BreakpointMixin';
import Navigation from '@/components/elements/Navigation';
import LanguageSwitcher from '@/components/elements/LanguageSwitcher';
import SocialMediaLink from '@/components/elements/SocialMediaLink';

@Component({
  components: { Navigation, LanguageSwitcher, SocialMediaLink }
})
export default class HeaderNav extends mixins(BreakpointMixin) {
  isProd: boolean = process.env.NUXT_APP_ENVIRONMENT === 'prod';
  @InjectReactive() readonly navOpen!: boolean;
  get nav (): BlokItemLink[] {
    return getGlobal(this.$store).headerNav;
  }
  get nav_additional (): BlokItemLink[] {
    return getGlobal(this.$store).headerNavAdditional;
  }
  get socials (): BlokItemSocialMedia[] {
    return getGlobal(this.$store).socials;
  }
  get address (): StoryblokRichText|false {
    return getGlobal(this.$store).address;
  }
  // -> GSAP animation
  $refs!: { header: Element };
  @Watch('navOpen')
  animateNav (newVal) {
    if (newVal === true && this.breakpointUp('md')) {
      const timeline = this.$gsap.timeline({
        defaults: {
          duration: 0.8,
          ease: 'expo.out'
        }
      });
      timeline.from(this.$refs.header.children[0], { y: -1000 });
      timeline.from(this.$refs.header.children[0].children[0], { y: -2500 }, '<+=0.2');
      timeline.from(this.$refs.header.children[0].children[1], { y: -2500 }, '<');
      timeline.from(this.$refs.header.children[1], { y: -1800 }, '<');
      timeline.from(this.$refs.header.children[1].children[0], { y: -2500 }, '<+=0.2');
      timeline.from(this.$refs.header.children[1].children[1], { y: -2500 }, '<');
      timeline.from(this.$refs.header.children[1].children[2], { y: -2500 }, '<');
    }
  }
}
