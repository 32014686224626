










import { Component, Prop, mixins } from 'nuxt-property-decorator';
import { StoryblokAsset } from '~/types';
import MediaMixin from '~/mixins/MediaMixin';

@Component
export default class VideoNative extends mixins(MediaMixin) {
  @Prop() content!: { video: StoryblokAsset };
  $refs !: { video: HTMLVideoElement };

  get poster (): string | false {
    if (!this.media.filename) {
      return false;
    }
    return this.generateImageUrl({
      width: 1600,
      height: 900,
      quality: 60
    });
  }

  showControls: boolean = false;

  startVideo (): void {
    this.showControls = true;
    if (this.$refs.video) {
      this.$refs.video.play();
    }
  }
}
