



































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { LinkTypes } from '~/types';

@Component
export default class LinkManual extends Vue {
  @Prop() url!: string; // if external then full url, otherwise path without locale code but trailing slash
  @Prop() title?: string; // only needed no slot is used
  @Prop() type!: LinkTypes;
  @Prop({ required: false, default: false }) is_button?: boolean;

  hasSlot: boolean = this.$slots.default !== undefined;
  finalUrl:string = '';

  get link (): string {
    return this.localePath({ path: `/${this.url}` });
  }

  get realUrl () :string {
    if (Array.from(this.url)[0] === '/') {
      this.finalUrl = this.url.substring(1);
      if (this.finalUrl.substring(this.finalUrl.length - 5) === '.pdf/') {
        return this.finalUrl.slice(0, -1);
      }
      return this.finalUrl;
    } else {
      return this.url;
    }
  }
}
