














import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichBox } from '~/types';

@Component
export default class RichBox extends Vue {
  @Prop() body!: BlokRichBox;
}
