








































import { Component, Watch } from 'nuxt-property-decorator';
import Vue from 'vue';
import { BlokItemLink, BlokItemSocialMedia, StoryblokRichText } from '../../../types';
import { getGlobal } from '../../../store';
import Navigation from '@/components/elements/Navigation';
import SocialMediaLink from '@/components/elements/SocialMediaLink';

@Component({
  components: { Navigation, SocialMediaLink }
})
export default class TheFooterBar extends Vue {
  loaded: boolean = false;
  isMobile: boolean = false;
  get claim (): StoryblokRichText|false {
    return getGlobal(this.$store).claim;
  }
  get address (): StoryblokRichText|false {
    return getGlobal(this.$store).address;
  }
  get nav (): BlokItemLink[] {
    return getGlobal(this.$store).footerNav;
  }
  get nav_additional (): BlokItemLink[] {
    return getGlobal(this.$store).footerNavAdditional;
  }
  get socials (): BlokItemSocialMedia[] {
    return getGlobal(this.$store).socials;
  }
  // -> GSAP animation
  get route (): any {
    return this.$route;
  }
  @Watch('route', {
    deep: true
  })
  routeChanged (newVal: any, oldVal: any): void {
    this.$gsap.set(this.$refs.footerbar, { opacity: 0 });
    this.appear();
  }
  $refs!: { footerbar: Element };
  appear (): void {
    this.$gsap.to(this.$refs.footerbar, { opacity: 1, delay: 2, duration: 2 });
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.footerbar, { opacity: 0 });
    this.appear();
    if (process.browser) {
      this.isMobile = window.innerWidth < 900;
    }
  }
  visibilityChanged (visible) {
    const footerHeight = this.$refs.footerbar.scrollHeight;
    const calendlyElement = document.getElementById('calendly-icon');
    const calendlyContent = document.getElementById('calendly-content');
    // const backToTopBtn = document.getElementById('top-btn-wrapper');
    // const backToTopBtnVisible = getComputedStyle(backToTopBtn).display !== 'none';
    if (visible) {
      if (calendlyElement) {
        calendlyElement.style.position = 'absolute';
        calendlyContent.style.position = 'absolute';
        calendlyElement.style.bottom = `${footerHeight}px`;
        calendlyContent.style.bottom = `${footerHeight}px`;
        calendlyElement.style.transition = 'right ease-in-out 250ms, bottom linear 0ms';
        calendlyContent.style.transition = 'right ease-in-out 250ms, bottom linear 0ms';
      }
    } else {
      calendlyElement.style.position = 'fixed';
      calendlyContent.style.position = 'fixed';
      calendlyElement.style.bottom = '';
      calendlyContent.style.bottom = '';
      setTimeout(() => {
        calendlyElement.style.transition = 'right ease-in-out 250ms, bottom ease-in-out 250ms';
        calendlyContent.style.transition = 'right ease-in-out 250ms, bottom ease-in-out 250ms';
      }, 250);
    }
  }
  /*
    visibilityChangedMobile (visible) {
      console.log(visible);
    }
  */
}
