















































import { Component, Watch } from 'nuxt-property-decorator';
import Vue from 'vue';
import { getGlobal } from '~/store';
import { StoryblokAsset } from '~/types';
import MediaHandler from '~/components/utilities/media/MediaHandler.vue';

@Component({
  components: { MediaHandler }
})
export default class Logo extends Vue {
  // -> GSAP animation
  is_minimized: boolean = false;
  @Watch('is_minimized')
  animateLogo (shrink: boolean): void {
    if (shrink) {
      this.$gsap.to('#svg-logo-typo', { x: -150 });
      this.$gsap.to('#svg-logo-icon', { x: -108, scale: 1.8 });
    } else {
      this.$gsap.to('#svg-logo-typo', { x: 0 });
      this.$gsap.to('#svg-logo-icon', { x: 0, scale: 1 });
    }
  }
  mounted () {
    window.addEventListener('scroll', () => {
      this.is_minimized = window.scrollY >= 500;
    });
  }
}
