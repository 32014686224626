import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { MediaTypes, StoryblokAsset } from '~/types';

interface GenerateImage {
  image?: StoryblokAsset,
  width?: number,
  height?: number,
  quality?: number,
  blur?: number,
  focal?: string | false,
  format?: string | false,
  dpi?: number
}

@Component
class MediaMixin extends Vue {
  @Prop() media!: StoryblokAsset;
  dpi: number = 1;
  imageurlSharp: string | false = false;
  get type (): MediaTypes {
    if (!this.media || !this.media.filename) {
      return 'image';
    }
    if (this.media.filename.endsWith('.mov') || this.media.filename.endsWith('.mp4') || this.media.filename.endsWith('.webm')) {
      return 'video';
    }
    return 'image';
  }
  get focal (): string | false {
    if (this.type === 'video') {
      return false;
    }
    return this.media.focus || false;
  }
  get imageurlLoading (): string {
    if (this.type === 'video') {
      return '';
    }
    return this.generateImageUrl({ quality: 5, blur: 50 });
  }
  generateImageUrl ({ image, width, height, quality, blur, focal, format, dpi }: GenerateImage): string {
    if (this.type === 'video') {
      return '';
    }
    image = image || this.media;
    dpi = dpi || 1;
    width = Math.floor((width || 100) * dpi);
    height = Math.floor((height || 0) * dpi);
    quality = quality || 100;
    blur = blur || 0;
    focal = focal || false;
    format = format || false;
    let link = `${image.filename}/m/${width}x${height}/filters:quality(${quality})`;
    if (blur) {
      link += `:blur(${blur})`;
    }
    if (focal) {
      link += `:focal(${focal})`;
    }
    if (format) {
      link += `:format(${format})`;
    }
    return link;
  }
  mounted () {
    this.dpi = Math.min(window.devicePixelRatio || 1, 2);
  }
}

export default MediaMixin;
