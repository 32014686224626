import { render, staticRenderFns } from "./RichVideoYoutube.vue?vue&type=template&id=37fed3f2&scoped=true&"
import script from "./RichVideoYoutube.vue?vue&type=script&lang=ts&"
export * from "./RichVideoYoutube.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37fed3f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoYoutube: require('/opt/build/repo/components/utilities/videos/VideoYoutube.vue').default})
