import { Component } from 'nuxt-property-decorator';
import Vue from 'vue';

@Component
class FixBodyMixin extends Vue {
  body: HTMLBodyElement | false = false;

  bodyIsFixated (isFixated: boolean): void {
    if (!this.body) {
      return;
    }
    isFixated ? this.body.classList.add('body-fixed') : this.body.classList.remove('body-fixed');
  }

  mounted () {
    this.body = process.browser ? document.querySelector('body') || false : false;
  }
}

export default FixBodyMixin;
