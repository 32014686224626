





























import { Component, mixins, Prop } from 'nuxt-property-decorator';
import MediaMixin from '../../../mixins/MediaMixin';
import { MediaLayouts } from '../../../types';
import LightboxMixin from '../../../mixins/LightboxMixin';
import Lightbox from '~/components/elements/Lightbox.vue';

@Component({
  components: { Lightbox }
})
export default class MediaHandler extends mixins(MediaMixin, LightboxMixin) {
  @Prop() layout!: MediaLayouts;
  @Prop({ required: false, default: false }) lightbox?: boolean;
  @Prop({ required: false, default: () => { return {}; } }) data?: { [key: string]: any };

  // --> layouts
  layoutsImages: { [key: MediaLayouts]: { component: string } } = {
    containing: {
      component: 'ImageContaining'
    },
    filling: {
      component: 'ImageFilling'
    },
    gallery: {
      component: 'ImageGallery'
    },
    graphics: {
      component: 'ImageGraphics'
    },
    hero: {
      component: 'ImageHero'
    }
  };
  layoutsVideo: { [key: MediaLayouts]: { component: string } } = {
    containing: {
      component: 'VideoMedia'
    },
    filling: {
      component: 'VideoMedia'
    },
    gallery: {
      component: 'VideoMedia'
    },
    graphics: {
      component: 'VideoMedia'
    },
    hero: {
      component: 'VideoMedia'
    }
  };
}
