import * as net from 'net';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';

// --> imported modules
import global from './global';
import blog from './pages/blog';
import projects from './pages/projects';
import methods from './pages/methods';
import people from './entities/people';
import awards from './entities/awards';
import customers from './entities/customers';
import network from './entities/network';
import blogcategories from './datasources/blogcategories';
import projectcategories from './datasources/projectcategories';

// --> modules global
export function getGlobal (store: Store<any>): global {
  return getModule(global, store);
}

// --> modules pages
export function getBlog (store: Store<any>): blog {
  return getModule(blog, store);
}
export function getProjects (store: Store<any>): projects {
  return getModule(projects, store);
}
export function getMethods (store: Store<any>): methods {
  return getModule(methods, store);
}

// --> modules entities
export function getPeople (store: Store<any>): people {
  return getModule(people, store);
}
export function getAwards (store: Store<any>): awards {
  return getModule(awards, store);
}
export function getCustomers (store: Store<any>): customers {
  return getModule(customers, store);
}
export function getNetwork (store: Store<any>): network {
  return getModule(network, store);
}

// --> modules datasources
export function getBlogcategories (store: Store<any>): blogcategories {
  return getModule(blogcategories, store);
}

export function getProjectcategories (store: Store<any>): projectcategories {
  return getModule(projectcategories, store);
}
