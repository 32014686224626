
















import { Component, mixins, ProvideReactive, Watch } from 'nuxt-property-decorator';
import FixBodyMixin from '../../../mixins/FixBodyMixin';
import Logo from '~/components/elements/Logo.vue';
import HeaderNav from '~/components/layout/header/elements/HeaderNav.vue';
import NavToggle from '~/components/layout/header/elements/NavToggle.vue';
import LanguageSwitcher from '~/components/elements/LanguageSwitcher.vue';
import BreakpointMixin from '~/mixins/BreakpointMixin';

@Component({
  components: { Logo, HeaderNav, LanguageSwitcher, NavToggle }
})

export default class TheMenuBar extends mixins(BreakpointMixin, FixBodyMixin) {
  @ProvideReactive() navOpen: boolean = false;
  toggleNav (newValue: boolean): void {
    this.navOpen = newValue;
    this.bodyIsFixated(newValue);
    this.animate(newValue);
    newValue && process.browser ? document.body.classList.add('nav-open') : document.body.classList.remove('nav-open');
  }

  // -> GSAP animation
  scrollPosition: number = 0;
  animate (newValue: boolean): void {
    if (newValue) {
      this.$gsap.to('.gsap-headernav', { y: 0 });
    } else {
      this.$gsap.to('.gsap-headernav', { y: '-100vh' });
    }
  }
  hideIfBarIfScrollingDown (prev: number, current: number): void {
    if (prev < 1000 || current < 1000) {
      this.$gsap.to('#menubar', { y: 0 });
      return;
    }
    if (prev < current) {
      if (prev + 3 >= current) {
        return;
      }
      this.$gsap.to('#menubar', { y: -100, duration: 0.5 });
    } else if (prev > current) {
      this.$gsap.to('#menubar', { y: 0, duration: 0.5 });
    }
  }
  showBar (): void {
    this.$gsap.to('#menubar', { y: 0 });
  }
  mounted () {
    if (!process.browser) { return; }
    this.scrollPosition = window.scrollY;
    let prevScrollPosition = window.scrollY;
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY;
      const currentScrollPosition = window.scrollY;
      if (window.scrollY === 0) {
        this.showBar();
        return;
      }
      this.hideIfBarIfScrollingDown(prevScrollPosition, currentScrollPosition);
      prevScrollPosition = currentScrollPosition;
    });
  }
}
