








import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichVideoVimeo, BlokRichVideoYoutube } from '~/types';
import VideoVimeo from '~/components/utilities/videos/VideoVimeo.vue';

@Component({
  components: { VideoVimeo }
})
export default class RichVideoVimeo extends Vue {
  @Prop() body!: BlokRichVideoVimeo;
}
