import { render, staticRenderFns } from "./RichImage.vue?vue&type=template&id=4700b794&"
import script from "./RichImage.vue?vue&type=script&lang=ts&"
export * from "./RichImage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaHandler: require('/opt/build/repo/components/utilities/media/MediaHandler.vue').default})
