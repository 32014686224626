import { render, staticRenderFns } from "./TheMenuBar.vue?vue&type=template&id=0f6c082d&scoped=true&"
import script from "./TheMenuBar.vue?vue&type=script&lang=ts&"
export * from "./TheMenuBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f6c082d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/elements/Logo.vue').default,NavToggle: require('/opt/build/repo/components/layout/header/elements/NavToggle.vue').default,HeaderNav: require('/opt/build/repo/components/layout/header/elements/HeaderNav.vue').default})
