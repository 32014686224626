








import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichVideoYoutube } from '~/types';
import VideoYoutube from '~/components/utilities/videos/VideoYoutube.vue';

@Component({
  components: { VideoYoutube }
})
export default class RichVideoYoutube extends Vue {
  @Prop() body!: BlokRichVideoYoutube;
}
