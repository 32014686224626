















import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class Icon extends Vue {
  @Prop({ required: true }) icon!: string;
  @Prop({ required: false, default: '#000000' }) color?: string;
  @Prop() colorHover!: string;
  @Prop({ required: false, default: 1.5 }) size?: number;
  @Prop({ required: false, default: '' }) label?: string;
}

