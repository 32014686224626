




import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichMargin, BlokRichTable } from '~/types';

@Component
export default class RichMargin extends Vue {
  @Prop() body!: BlokRichMargin;
}
