import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { ContentPageBlog, ContentPageProject, ObjectLiteral, StoryblokResponse, StoryblokStory } from '~/types';

@Module({
  name: 'pages/blog',
  stateFactory: true,
  namespaced: true
})

export default class Blog extends VuexModule {
  // ---state
  data: ObjectLiteral = {
    de: [],
    en: []
  };

  loaded: string = '';

  // ---getters
  get allUuids (): string[] {
    const data = this.data[this.loaded] as StoryblokStory[];
    return data?.map((entry: StoryblokStory) => entry.uuid) || [];
  }

  get countOfUuidsExceptUuid () {
    return (uuid: string, count: number): string[] => {
      const data = this.data[this.loaded] as StoryblokStory[];
      return data?.filter((entry: StoryblokStory) => entry.uuid !== uuid).map((entry: StoryblokStory) => entry.uuid).slice(0, count) || [];
    };
  }

  get entryByUuid () {
    return (uuid: string): StoryblokStory | false => {
      const data = this.data[this.loaded] as StoryblokStory[];
      return data?.find((entry: StoryblokStory) => entry.uuid === uuid) || false;
    };
  }

  get contentByUuid () {
    return (uuid: string): ContentPageBlog | false => {
      const data = this.data[this.loaded] as StoryblokStory[];
      return data?.find((entry: StoryblokStory) => entry.uuid === uuid)?.content || false;
    };
  }

  get numberOfEntries (): number {
    return this.data[this.loaded].length || 0;
  }

  // ---mutations
  @Mutation
  setData ({ stories, language }: { stories: StoryblokStory[], language: string }): void {
    this.data[language] = stories.slice(0);
  }

  @Mutation
  setLoaded (language: string): void {
    this.loaded = language;
  }

  // --actions
  @Action({ rawError: true })
  async fetchData ({ version, api, language }: { version: string, api: any, language: string }) {
    if (this.loaded === language) {
      return;
    }

    const today = new Date();
    today.setHours(today.getHours() + 24);
    const reformattedToday = `${today.toISOString().slice(0, 10)} 00:00`;

    const res: StoryblokStory[] | null = await api
      .get('cdn/stories/', {
        version,
        language,
        'filter_query[component][in]': 'PageBlog',
        'filter_query[date_publication][lt_date]': reformattedToday,
        sort_by: 'content.date_publication:desc'
      })
      .then(({ data }: StoryblokResponse) => {
        if (data?.stories) {
          return data.stories;
        }
        return [];
      })
      .catch((err: any) => {
        console.log(err);
        return [];
      });
    if (res) {
      this.setData({ stories: res, language });
      this.setLoaded(language);
    }
  }
}
