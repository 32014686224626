import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import {
  BlokItemLink,
  BlokItemSocialMedia,
  ObjectLiteral, SeoHeader, StoreGlobals,
  StoryblokAsset,
  StoryblokResponse, StoryblokRichText,
  StoryblokStory
} from '~/types';

@Module({
  name: 'global',
  stateFactory: true,
  namespaced: true
})

export default class Global extends VuexModule {
  // ---state
  data: ObjectLiteral = {
    de: [],
    en: []
  };

  loaded: string = '';

  // ---getters
  get globals (): StoreGlobals|false {
    return this.data[this.loaded] || false;
  }

  get socials (): BlokItemSocialMedia[] {
    if (!this.globals) { return []; }
    return this.globals.socials || [];
  }

  get address (): StoryblokRichText|false {
    if (!this.globals) { return false; }
    return this.globals.address || false;
  }

  get claim (): StoryblokRichText|false {
    if (!this.globals) { return false; }
    return this.globals.claim || false;
  }

  get headerNav (): BlokItemLink[] {
    if (!this.globals) { return []; }
    return this.globals.nav_header || [];
  }

  get headerNavAdditional (): BlokItemLink[] {
    if (!this.globals) { return []; }
    return this.globals.nav_header_additional || [];
  }

  get footerNav (): BlokItemLink[] {
    return this.data[this.loaded]?.nav_footer || [];
  }

  get footerNavAdditional (): BlokItemLink[] {
    if (!this.globals) { return []; }
    return this.globals.nav_footer_additional || [];
  }

  get seo (): SeoHeader|false {
    if (!this.globals) { return false; }
    if (!this.globals.seo.length) { return false; }
    return this.globals.seo[0];
  }

  // ---mutations
  @Mutation
  setData ({ story, language }: { story: StoryblokStory, language: string }): void {
    this.data[language] = story.content;
  }

  @Mutation
  setLoaded (language: string) {
    this.loaded = language;
  }

  // ---actions
  @Action({ rawError: true })
  async fetchData ({ version, api, language }: { version: string, api: any, language: string }) {
    if (this.loaded === language) {
      return;
    }
    const res: StoryblokStory | null = await api
      .get('cdn/stories/global', { version, language })
      .then(({ data }: StoryblokResponse) => {
        if (data?.story) {
          return data.story;
        }
        return null;
      })
      .catch((err: any) => {
        console.log(err);
        return null;
      });
    if (res) {
      this.setData({ story: res, language });
      this.setLoaded(language);
    }
  }
}
