


























import { Vue, Component } from 'nuxt-property-decorator';
import { LocaleObject } from '@nuxtjs/i18n';

@Component
export default class LanguageSwitcher extends Vue {
  languageIndicator: 'code' | 'iso' | 'name' = 'code';
  get currentLocale (): LocaleObject {
    return this.$i18n.localeProperties;
  }
  get availableLocales (): LocaleObject[] {
    return this.$i18n.locales;
  }
}
