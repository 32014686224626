import { Plugin } from '@nuxt/types';

declare module '@nuxt/types' {
  interface Context {
    $pwaUpdate(): void
  }
}
const pwaUpdate: Plugin = (context) => {
  context.$pwaUpdate = async () => {
    const w: Window = window;
    // @ts-ignore
    const workbox: any = await w.$workbox;
    if (!workbox) {
      console.debug("Workbox couldn't be loaded.");
      return;
    }
    workbox.addEventListener('installed', (event: any) => {
      if (!event.isUpdate) {
        console.debug('The PWA is on the latest version.');
        return;
      }
      console.debug('There is an update for the PWA, reloading...');
      window.location.reload();
    });
  };
};
export default pwaUpdate;
