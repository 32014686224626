









import { Component, Prop, mixins } from 'nuxt-property-decorator';
import MediaMixin from '~/mixins/MediaMixin';

@Component
export default class VideoVimeo extends mixins(MediaMixin) {
  @Prop() content!: { id: string };
  $refs!: { vimeoVideo: HTMLUnknownElement };

  get poster (): string | false {
    if (!this.media.filename) {
      return false;
    }
    return this.generateImageUrl({
      width: 1600,
      height: 900,
      quality: 60
    });
  }

  mounted () {
    this.$refs.vimeoVideo.style.backgroundImage = this.media ? `url('${this.poster}')` : '';
  }
}
