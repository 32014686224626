



















import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokRichTable } from '~/types';

@Component
export default class RichTable extends Vue {
  @Prop() body!: BlokRichTable;
}
