import { render, staticRenderFns } from "./TheFooterBar.vue?vue&type=template&id=4db6c334&scoped=true&"
import script from "./TheFooterBar.vue?vue&type=script&lang=ts&"
export * from "./TheFooterBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db6c334",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/opt/build/repo/components/elements/Navigation.vue').default,SocialMediaLink: require('/opt/build/repo/components/elements/SocialMediaLink.vue').default})
