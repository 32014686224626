import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { ContentEntityPeople, ObjectLiteral, StoryblokResponse, StoryblokStory } from '~/types';

@Module({
  name: 'entities/people',
  stateFactory: true,
  namespaced: true
})

export default class People extends VuexModule {
  // --state
  data: ObjectLiteral = {
    de: [],
    en: []
  };

  loaded: string = '';

  // --getters
  get allUuids (): string[] {
    const data = this.data[this.loaded] as StoryblokStory[];
    return data?.map((item: StoryblokStory) => item.uuid) || [];
  }

  get dataByUuid () {
    return (uuid: string): ContentEntityPeople|false => {
      const data = this.data[this.loaded] as StoryblokStory[];
      return data?.find((item: StoryblokStory) => item.uuid === uuid)?.content || false;
    };
  }

  // --mutations
  @Mutation
  setData ({ stories, language }: { stories: StoryblokStory[], language: string }): void {
    this.data[language] = stories.slice(0);
  }

  @Mutation
  setLoaded (language: string): void {
    this.loaded = language;
  }

  // --actions
  @Action
  async fetchData ({ version, api, language }: { version: string, api: any, language: string }) {
    if (this.loaded === language) {
      return;
    }

    const res: StoryblokStory[] | null = await api
      .get('cdn/stories/', {
        version,
        language,
        per_page: '100',
        'filter_query[component][in]': 'EntityPeople'
      })
      .then(({ data }: StoryblokResponse) => {
        if (data?.stories) {
          return data.stories;
        }
        return [];
      })
      .catch((err: any) => {
        console.log(err);
        return [];
      });
    if (res) {
      this.setData({ stories: res, language });
      this.setLoaded(language);
    }
  }
}
