import Vue from 'vue';
import { Component, mixins } from 'nuxt-property-decorator';
import FixBodyMixin from './FixBodyMixin';

@Component
class LightboxMixin extends mixins(FixBodyMixin) {
  lightboxOpen: boolean = false;

  head () {
    return {
      bodyAttrs: {
        style: this.lightboxOpen ? 'overflow: hidden' : ''
      }
    };
  }

  openLightbox (hasLightbox: boolean): void {
    if (hasLightbox) {
      this.lightboxOpen = true;
      this.bodyIsFixated(true);
    }
  }

  closeLightbox (hasLightbox: boolean): void {
    if (hasLightbox) {
      this.lightboxOpen = false;
      this.bodyIsFixated(false);
    }
  }
}

export default LightboxMixin;
