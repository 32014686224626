










import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokItemLink } from '../../types';
import LinkHandler from '@/components/utilities/links/LinkHandler';

@Component({
  components: { LinkHandler }
})
export default class Navigation extends Vue {
  @Prop() items!: BlokItemLink[];
}
