import { render, staticRenderFns } from "./RichVideoVimeo.vue?vue&type=template&id=8c1f24f6&scoped=true&"
import script from "./RichVideoVimeo.vue?vue&type=script&lang=ts&"
export * from "./RichVideoVimeo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c1f24f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoVimeo: require('/opt/build/repo/components/utilities/videos/VideoVimeo.vue').default})
