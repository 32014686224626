







































import { Component } from 'nuxt-property-decorator';
import Vue from 'vue';

@Component({
  components: {}
})
export default class CalendlyButton extends Vue {
  openContent:boolean = false;
  getCookie (cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      // eslint-disable-next-line eqeqeq
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      // eslint-disable-next-line eqeqeq
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  mounted () {
    if (process.browser) {
      // const notFirstTimer = localStorage.getItem('not-first-timer') || false;
      const settedCookie = this.getCookie('cookie_control_consent') || false;
      const width = window.innerWidth;
      // if (notFirstTimer && settedCookie) {
      if (settedCookie && width >= 900 && process.env.NUXT_APP_ENVIRONMENT === 'prod') {
        setTimeout(() => {
          this.openContent = true;
          setTimeout(() => {
            this.openContent = false;
          }, 3000);
        }, 3000);
      }
    }
  }
}
