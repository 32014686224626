












import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokItemLink, BlokRichCallToAction } from '~/types';
import ButtonWrapper from '~/components/utilities/buttons/ButtonWrapper.vue';
import LinkHandler from '~/components/utilities/links/LinkHandler.vue';

@Component({
  components: {
    ButtonWrapper,
    LinkHandler
  }
})
export default class RichCallToAction extends Vue {
  @Prop() body!: BlokRichCallToAction;

  get hasLink (): boolean {
    return !!this.body.link.length;
  }

  get linkObject (): BlokItemLink | undefined {
    return this.hasLink ? this.body.link[0] || undefined : undefined;
  }
}
