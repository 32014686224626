










import { Component, Vue } from 'nuxt-property-decorator';

@Component({
  transition: {
    name: 'page',
    mode: 'out-in'
  }
})

export default class LayoutDefault extends Vue {
  isProd: boolean = process.env.NUXT_APP_ENVIRONMENT === 'prod';
  language: string = this.$i18n.locale;

  mounted () {
    // -> fix scroll bug
    if (process.browser) {
      this.$ScrollTrigger.config({
        autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load'
      });
    }
    // -> implement local storage item for first time loading
    const notFirstTimer = localStorage.getItem('not-first-timer');
    if (!notFirstTimer) {
      localStorage.setItem('not-first-timer', 'false');
    } else if (notFirstTimer && !JSON.parse(notFirstTimer)) {
      localStorage.setItem('not-first-timer', 'true');
    }
  }

  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta
      ]/*,
      link: [
        ...i18nHead.link
      ] */
    };
  };
}
